import React from 'react'
import { withNamespaces } from "react-i18next";
import LanguageSwitcher from '../components/languageSwitcher';
import Loader from "../components/loader";
import Layout from "../components/layout";
import '../assets/scss/_marketingshop.scss'

const idMission = "bran0"

const Modal = ({ handleClose, show, children, title, t }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';
  
    return (
      <div className={showHideClassName}>
        <section className='modal-main'>
        <h2>{title}</h2>
        <div className='modal-body'>
        {children}
        </div>
        <div className='modal-footer'>
          <button className="btn-sm btn-primary"
            onClick={handleClose}
          >
            {t('close')}
          </button>
          </div>
        </section>
      </div>
    );
  };

class BranMarketing extends React.Component {

    state = {
        buying : 0, // 0 = start ; 1 = running ; 2 = success ; 3 = err
        tab:1
    }

    showBuyPopup() {
        /*this.setState({
            buying: 1
        })*/
    }

    componentDidMount() {
        //this.props.cleanupKart();

        /*MissionsHelper.getMissionsAsync()
        .then(missions => {
            this.props.addToKart(missions[idMission]);
        })*/
    }

    callBuyTransaction = (token, recipient) => {
        const { buyMethod, cart, discount, email } = this.props
    
        this.setState({
          loading: true,
        })
    
        buyMethod(token, cart, email || recipient.email, discount, recipient)
          .then(() => {
            this.setState({
              loading: false,
              buying: 2
            })
          })
          .catch(err => {
            console.log(err, {...err})
            const { details } = err;
            if (details) {
              const { detailedMessage, paymentProceeded } = details;
              this.setState({
                error: paymentProceeded ? 'error-shop-after-payment' : 'error-shop-before-payment',
                detailedMessage,
                loading: false,
                showErrorModal: true,
                buying: 3
              })
            } else {
              this.setState({
                error: 'error-shop-before-payment',
                detailedMessage: '',
                loading: false,
                showErrorModal: true,
                buying: 3
              })
            }
          })
      }    

    hideModal = () => {
        this.setState({ showErrorModal: false });
    }

    showThanksMessage = () => {
        const { t } = this.props
        return (
            <div className="window" id="buy-done">
                <h3>{t('congrats')}</h3>
                <p>{t('check-mail')}</p>
                <div id="back-done-container">
                    <button>{t('back')}</button>
                </div>
            </div>
        )
    }    

    activeTab(tab) {
        this.setState({
            tab
        });
    }

    renderInfo() {
        return (
        <div>
            <div className={(this.state.tab === 1)?"active tab-content":"tab-content"} >
                <div className='facts-container'>
                    <div className='facts'>
                        <div className="col-xs-12 col-md-4 col-lg-3 list ">
                            <img src={require('../images/marketing/bran/gps.png')} className="info-icon"/>
                            <p>
                                <b>START POINT</b>
                                <br/>
                                Castle's Garden
                            </p>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-3 list"> 
                            <img src={require('../images/marketing/bran/timer.png')} className="info-icon"/>
                            <p>
                                <b>DURATION</b>
                                <br/>
                                30 minutes
                            </p>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-3 list"> 
                            <img src={require('../images/marketing/bran/family.png')} className="info-icon"/>
                            <p>
                                <b>Accessibility</b>
                                <br/>
                                8 and +
                            </p>
                        </div>  
                    </div>
                    
                    <div className="fresh-air"></div>
                    
                    <div className='facts'>
                        <div className="col-xs-12 col-md-4 col-lg-3 list ">
                            <img src={require('../images/marketing/bran/chat.png')} className="info-icon"/>
                            <p>
                                <b>PLAYABLE IN</b>
                                <br/>
                                <div className="flags">
                                    <img src={require("../images/marketing/bran/flags/ro@4x.png")} />
                                    <img src={require("../images/marketing/bran/flags/ru@4x.png")} />
                                    <img src={require("../images/marketing/bran/flags/en@4x.png")} />
                                    <img src={require("../images/marketing/bran/flags/fr@4x.png")} />
                                    <img src={require("../images/marketing/bran/flags/de@4x.png")} />
                                </div>
                            </p>
                        </div>
                        <div className="col-xs-12 col-md-4 col-lg-3 list"> 
                            <img src={require('../images/marketing/bran/difficulty.png')} className="info-icon"/>
                            <p>
                                <b>Difficulty</b>
                                <br/>
                                Easy
                            </p>
                        </div>  
                        <div className="col-xs-12 col-md-4 col-lg-3 list"> 
                            <img src={require('../images/marketing/bran/price.png')} className="info-icon"/>
                            <p>
                                <b>Price</b>
                                <br/>
                                1.99 €
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div className={(this.state.tab === 2)?"active tab-content":"tab-content"} >
                <div className='story'>
                    <center>
                        We’ve arrested two suspects returning from 1462 in Bran Castle. After an interrogation, they confessed to be at the origin of a plot to destroy Dracula's myth.
                        <br/>
                        <br/>
                        <strong style={{textTransform:"uppercase"}}>History may have been modified.</strong> 
                        <br/>
                        <br/>
                        Your mission is to get back to 1462 to ensure they didn't change anything.
                    </center>
                </div>
            </div>


            <div className={(this.state.tab === 3)?"active tab-content":"tab-content"} >
                <div className='about'>
                    <span className="informations"></span>
                    <ul>
                        <li>
                            The game is played autonomously using the application Atlantide (available on Android and iPhone).
                        </li>                        <li>
                            The code you obtain can be used on 1 device, suitable for a group of 3 peoples
                        </li>
                        <li>
                            The game is available in English, Romanian, Russian, French and German
                        </li>
                        <li>
                            All the historical facts has been validated by professionals.
                        </li>
                    </ul>
                    
                </div>
            </div>
            
        </div>
        )
    }

    render() {
        const { t, cart } = this.props;

        return (
            <Layout>
                <Loader />
                <div id="ce" className="marketing">
                    <div className="Topbar__language hidden">
                        <LanguageSwitcher callback={() => { this.setState({ln: t("manual.ln") })} }/>
                    </div>

                    <img src={require("../images/marketing/bran/header.png")} className='header'/>
                    <h1>Live a Unique experience with Dracula</h1>
                    
                    <div className="container">
                        <div className="row">
                            <div className="col-l-8 col-12 col-l-offset-2">
                                <h2 className="light">
                                    Inspired by the escape games, play an outdoor geolocated game on mobile based on historical facts.
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className="fresh-air"></div>
                    <div className="fresh-air"></div>

                    <div className="row">
                        <div className="col-l-10 col-12 col-l-offset-1">
                            <p>
                                <strong>Have fun in the legendary castle gardens’</strong> and save the famous myth of Dracula.
                                <br/>
                                For the first time, the secrets of Vlad the Impaler in an <strong>outdoor adventure</strong> on mobile.
                            </p>
                        </div>
                    </div>

                    <div className="fresh-air"></div>
                    <div className="fresh-air"></div>

                    <a href='http://www.youtube.com?v=8bf5PD'><img className=" hidden youtube" src={require("../images/marketing/bran/youtube.svg")} /></a>

                    <div className="fresh-air"></div>
                    <div className="fresh-air"></div>
                    
                    <h2>
                        Chapter 1
                    </h2>

                    <img src={require("../images/marketing/bran/vlad.png")} className='vlad'/>
                    
                    <h2 className="upper">
                        THE LEGEND OF DRACULA
                    </h2>

                    <div className="fresh-air"></div>
                    <div className="fresh-air"></div>

                    <div className="my-container">
                        <div className="col-xs-12 col-lg-8 col-lg-offset-2 info">
                            <div className="tabs">
                                <div className={(this.state.tab === 1)?"active":""} onClick={() => { this.activeTab(1) }}>INFORMATIONS</div>
                                <div className={(this.state.tab === 2)?"active":""} onClick={() => { this.activeTab(2) }}>Your mission</div>
                                <div className={(this.state.tab === 3)?"active":""} onClick={() => { this.activeTab(3) }}>FAQ</div>
                            </div>
                            {
                                this.renderInfo()
                            } 
                        </div>
                    </div>


                    <div className="btn buy hidden" onClick={() => { this.showBuyPopup ()  }}>GET IT NOW</div>
                    
                
                    <div className='store'>
                        <a href='https://itunes.apple.com/fr/app/atlantide-io/id1364973121' target="_blank">
                            <img src={require('../images/marketing/bran/app_store.png')}/>
                        </a>
                        
                        <a href='https://play.google.com/store/apps/details?id=com.magnitudelabs.atlantide' target="_blank">
                            <img src={require('../images/marketing/bran/play_store.png')}/>
                        </a>
                    </div>
                    
                    <a href='https://www.atlantide.io' target="_blank">
                        <img src={require('../images/marketing/bran/atlantide.png')} id='logo'/>
                    </a>

                    <section id="Project-page" className="project-page space-from-topbar">
                        <div className="container">
                            {(this.state.buying === 1) ?
                                <div className="window">
                                    {/*
                                    <CartView
                                    cart={cart}
                                    discount={0}
                                    callBuyTransaction={this.callBuyTransaction}
                                    setLoading={isLoading => this.setState({ loading: isLoading })}
                                    continuePurchase={() => {
                                        this.setState({
                                        showCart: false,
                                        })
                                    }}
                                    acceptMultiplePurchase={false}
                                    buyMethod={this.props.buyMethod}
                                    t={t}
                                    />
                                    */}
                                </div>:
                                null
                            }
                        </div>
                    </section>
                    {
                        this.state.buying != 2
                        ? null
                        : this.showThanksMessage()
                    }
                        <Modal show={this.state.showErrorModal} handleClose={this.hideModal} title={t('error')} t={t} >
                          <p><strong>{t(this.state.error)}</strong></p>
                          <p>{this.state.detailedMessage}</p>
                        </Modal>
                      <div className="fresh-air" />
                </div>
        </Layout>)   
    }
}

  
export default withNamespaces()(BranMarketing)